import * as auth from './actions/public/auth'
import * as settings from './actions/public/settings'
import * as medias from './actions/public/medias'
import * as documents from './actions/public/documents'
import * as user from './actions/public/user'
import * as trusted from './actions/public/trusted'
import * as trusters from './actions/public/trusters'
import * as tresor from './actions/public/tresor'
import * as notifications from './actions/public/notifications'
import * as categories from './actions/public/categories'
import * as support from './actions/public/support'
import * as plans from './actions/public/plans'
import * as checklists from './actions/public/checklists'
import * as personaldocs from './actions/public/personaldocs'
import * as advisor from './actions/public/advisor'
import * as documenttemps from './actions/public/documenttemps'
import * as up from './actions/public/up'
import * as contact from './actions/public/contact'
import * as lastwish from './actions/public/lastwish'

import * as adminAuth from './actions/admin/auth'
import * as adminCertificates from './actions/admin/certificates'
import * as adminUsers from './actions/admin/users'
import * as adminPlans from './actions/admin/plans'
import * as adminEmails from './actions/admin/emails'
import * as adminSupports from './actions/admin/supports'
import * as adminSettings from './actions/admin/settings'
import * as adminPays from './actions/admin/pays'
import * as adminRoles from './actions/admin/roles'
import * as adminAdmins from './actions/admin/admins'
import * as adminChecklists from './actions/admin/checklists'
import * as adminDocumenttemps from './actions/admin/documenttemps'

import * as adminUserMedias from './actions/admin/public/medias'
import * as adminUserDocuments from './actions/admin/public/documents'
import * as adminUserUser from './actions/admin/public/user'
import * as adminUserTrusted from './actions/admin/public/trusted'
import * as adminUserTrusters from './actions/admin/public/trusters'
import * as adminUserTresor from './actions/admin/public/tresor'
import * as adminUserNotifications from './actions/admin/public/notifications'
import * as adminUserCategories from './actions/admin/public/categories'
import * as adminUserSupport from './actions/admin/public/support'
import * as adminUserPlans from './actions/admin/public/plans'

const actions = Object.assign({},
    auth.default,
    settings.default,
    medias.default,
    documents.default,
    user.default,
    trusted.default,
    trusters.default,
    tresor.default,
    notifications.default,
    categories.default,
    support.default,
    plans.default,
    checklists.default,
    personaldocs.default,
    advisor.default,
    documenttemps.default,
    up.default,
    contact.default,
    lastwish.default,

    adminAuth.default,
    adminCertificates.default,
    adminUsers.default,
    adminPlans.default,
    adminEmails.default,
    adminSupports.default,
    adminSettings.default,
    adminPays.default,
    adminRoles.default,
    adminAdmins.default,
    adminChecklists.default,
    adminDocumenttemps.default,

    adminUserMedias.default,
    adminUserDocuments.default,
    adminUserUser.default,
    adminUserTrusted.default,
    adminUserTrusters.default,
    adminUserTresor.default,
    adminUserNotifications.default,
    adminUserCategories.default,
    adminUserSupport.default,
    adminUserPlans.default,
);

export default actions