import axios from 'axios'
import { data } from 'jquery'
import Vue from 'vue'

export default {
    async adminUsergetNotifications({ commit }, user_id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/notifications`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserGetLastNotifications({ commit }, user_id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/notifications/toolbar`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}