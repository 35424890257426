import axios from 'axios'
import { data } from 'jquery'
import Vue from 'vue'

export default {
    async adminUsergetTrusterUsers({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/trusterUsers`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsersetRejectTruster({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trusterUsers/reject`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsersetAcceptTruster({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trusterUsers/accept`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsercertificateUpload({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/certificates`, data: data.data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetUploadedCertificates({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/certificates`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}