export default {
    new_admin: 'Neuer Admin',
    admins: 'Admins',
    edit_admin: 'Bearbeite Admin',

    role: 'Aufgabe',
    roles: 'Aufgaben',
    edit_role: 'Bearbeite Aufgabe',
    role_name: 'Aufgabenname',
    enter_role_name: 'Bitte gebe einen Aufgabennamen an!',
    select_permissions: 'Bitte wähle eine Zuständigkeit aus!',
    role_updated: 'Aufgabe aktualisiert',
    new_role: 'Neue Aufgabe',
    delete_role_alarm: 'Beim Löschen wird diese Aufgabe gelöscht, bist du sicher?',

    emails: 'Emails',
    pays: 'Zahlungen',
    settings: 'Einstellungen',

    all_users: 'Alle Benutzer',
    some_user: 'Einige Benutzer',
    users: 'Benutzer',

    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    died: 'tot',
    has_key: 'Hat Schlüssel',
    reffered: 'zugewiesen',
    debt: 'Schuld',

    plans: 'Preismodell',
    all_plans: 'Alle Preismodelle',

    email_templates: 'Email Vorlage',
    new_template: 'Neue Vorlage',
    email_created: 'Neue Email Vorlage erstellt',
    template: 'Vorlage',
    send_to: 'Senden an',
    sended_emails: 'gesendete Emails',
    new_email: 'Neue Email',
    send_new_email: 'Neue Email senden',
    send_email: 'Sende Email',
    to: 'an',
    sended_at: 'gesendet an',
    email_sended: 'Email gesendet!',
    support_tickets: 'Support Anfrage',
    supports: 'Support',
    all: 'Alle',
    successful: 'Erledigt',
    accepts: 'Accepts',

    create_email: 'Erstelle neue Email Vorlage',
    attach_files: 'Anhang',
    select_attach: 'Wähle Anhang',
    file_name: 'Dateiname',
    create: 'Erstelle',

    all_success_pays: 'Jeder Erfolg zahlt sich aus',
    income: 'Einkommen',
    filter_from: 'Filter: Von',
    filter_to: 'Filter: Bis',

    ref_id: 'Referenz ID',
    payer_id: 'Zahlungs-ID',
    failed: 'Nicht bezahlt',
    success: 'Bezahlt',
    started: 'Beginn',

    edit_plan: 'Bearbeite Preismodell',
    plan_name: 'Name Preismodell',
    plan_desc: 'Preismodell Beschreibung',
    plan_dur: 'Laufzeit Preismodell (Tage)',
    plan_price: 'Preismodell Preis',
    plan_price_year: 'Preismodell Preis/Jahr',
    plan_price_month: 'Preismodell Preis/Monat',
    plan_currency: 'Preismodell Währung',
    new_plan: 'Neues Preismodell',
    create_new_plan: 'Erstelle neues Preismodell',
    plan_shop_link: 'Shop-Link',

    update: 'Speichern',

    dur_days: 'Laufzeit (Tage)',

    account_information: 'Benutzerkonten Informationen',

    general: 'Allgemein',
    home_page: 'Home Page',
    icons: 'Icons',
    contact_us: 'Kontakt',
    contact_us_settings: 'Kontakt Einstellungen',
    general_settings: 'Allgemeine Einstellungen',
    site_title: 'Titel',
    site_subtitle: 'Seiten Untertitel',
    site_copyright: 'Seiten Copyright',
    about: 'Über uns',
    impressum: 'Impressum',
    datenschutz: 'Datenschutz',
    home_settings: 'Startseite Einstellungen',
    home_video: 'Startseite Video',
    backgroud_image: 'Hintergrund Bild',

    txt1_title: 'Textfeld 1: Titel',
    txt1_content: 'Textfeld 1: Inhalt',

    txt2_title: 'Textfeld2: Titel',
    txt2_content: 'Textfeld 2: Inhalt',

    txt3_title: 'Textfeld 3: Titel',
    txt3_content: 'Textfeld 3: Inhalt',

    txt4_title: 'Textfeld 4: Titel',
    txt4_content: 'Textfeld 4: Inhalt',

    image_menu: 'Kachel-Menü',
    image: 'Kachel',
    link_url: 'Link Url',
    family_img1: 'Familie Bild 1',
    family_img2: 'Familie Bild 2',
    price_table: 'Preismodell Bild', 
    footer_logo1: 'Footer Logo 1',
    footer_logo2: 'Footer Logo 2',
    footer_logo3: 'Footer Logo 3',
    top_footer_text: 'Top Footer Text',
    logged_home: 'Startseite Login-Bereich',
    banner_title: 'Banner Titel',
    banner_content: 'Banner Inhalt',
    banner_btn_lbl: 'Banner Button Lable',
    banner_btn_url: 'Banner Button Url',
    banner_image: 'Banner Bild',

    done: 'Geschafft!',

    close_ticket: 'Erledige Supportanfrage',

    send_notif: 'Sende allen Nutzern eine Benachrichtigung',
    send_notif_to: 'Sende Benachrichtigung an',
    send: 'Senden',
    notif_sended: 'Benachrichtigung gesendet!',

    trash: 'Papierkorb',
    joined_at: "Joined At",

    content: 'Inhalt',

    last_updated: 'Letzte Aktualisierung',

    checklist: 'Checkliste',
    new_item: 'Neuer Eintrag',
    new_checklist_item: 'Neuer Eintrag',
    edit_checklist_item: 'Checklisten-Eintrag bearbeiten',
    order: 'Reihenfolge',
    checklist_name_empty: 'Bitte benennen Sie den neuen Eintrag',
    checklist_status_empty: 'Bitte wählen Sie den Status des Eintrags',
    checklist_item_created: 'Neuer Checklisten-Eintrag wurde erstellt',
    checklist_item_updated: 'Checklisten-Eintrag wurde aktualisiert',

    new_dokumentvorlagen_item: 'Neue Dokumentenvorlage',
    dokumentvorlagen_name_empty: 'Bitte benennen Sie die Dokumentenvorlage',
    dokumentvorlagen_desc_empty: 'Bitte beschreiben Sie die Dokumentenvorlage',
    dokumentvorlagen_file_empty: 'Bitte wählen Sie eine Datei aus',
    dokumentvorlagen_item_created: 'Neue Dokumentenvorlage wurde erstellt',

    fav_icon: 'Favicon',
    white_logo: 'Logo für weißen Hintergrund',
    dark_logo: 'Logo für dunklen Hintergrund',
    icons_settings: 'Einstellungen für Website-Symbole',

    enter_otp_to_disable: 'Geben Sie die pushTan ein, um die 2-Faktor-Authentisierung auszuschalten',
    enter_otp_to_enable: 'Geben Sie die pushTan ein, um die 2-Faktor-Authentisierung zu aktivieren',
    two_factor_auth: 'Zwei-Faktor-Authentifizierung',
    secret_code: 'Secret Code',
    row_per_page: 'Zeilen pro Seite'
}