import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {
    async getFiles({ commit }) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault`, method: 'GET' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async deleteTresorFile({ commit }, data) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault/${data.id}`, method: 'DELETE' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async getConfidingFiles({ commit }, truster_id) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault/accessed/${truster_id}`, method: 'GET' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async getFile({ commit }, slug) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/files/${slug}`, method: 'GET' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async uploadTresorFile({ commit }, data) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault`, data: data, method: 'POST' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async getFileTrustedUsers({ commit }, id) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault/${id}/accessedUsers`, method: 'GET' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async setFileTrustedUsers({ commit }, data) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault/${data.id}/access`, data: data, method: 'POST' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async deleteFileTrustedUsers({ commit }, data) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/vault/${data.id}/removeAccess`, data: data, method: 'POST' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async tresorOtp({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/tresor/login`, data: data, method: 'POST' })
            .then(resp => {
                localStorage.setItem('tresorToken', resp.data.token)
                commit('set_tresor_token', resp.data.token)
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async getKeys({ commit }) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/key`, method: 'GET' })
            .then(resp => {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                resolve(resp.data)
            })
            .catch(err => {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                reject(err)
            })
        })
    },

    async checkTresorFeature({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/checkTresorFeature`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}