<template>
<section :style="`margin-top: ${data.margin_top};`">

    <v-row class="textarea py-10" id="textimgarea">
        <v-row justify="center" align="center">
            <v-col cols="10" md="6">
                <v-row>
                    <v-col justify="center" align="center">
                        <h1 class="textarea-title" v-if="data.content">{{ data.title }}</h1>
                        <h1 class="textarea-title" v-else v-html="data.title"></h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="textarea-content" v-html="data.content"></p>
                    </v-col>
                </v-row>
                <v-row v-if="data.icon" class="mt-10">
                    <v-col justify="center" align="center" align-self="center">
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="2" justify="center" align="center" align-self="center">
                        <img :src="data.icon" class="p-2 img-fluid">
                    </v-col>
                    <v-col justify="center" align="center" align-self="center">
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row v-if="data.photo" class="my-10">
                    <v-img :src="data.photo" :width="$helpers.isMobile() ? '100px': ''" class="shadow1"></v-img>
                </v-row>
                <v-row v-if="data.images" class="mt-10">
                    <v-col v-for="(image, i) in data.images" :key="i" cols="4" justify="center" align="center" align-self="center">
                        <img :src="image" class="textarea-icon">
                    </v-col>
                </v-row>
                <v-row v-if="data.title2" class="mt-16">
                    <v-col justify="center" align="center">
                        <h1 class="textarea-title">{{ data.title2 }}</h1>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" v-if="footer" class="footer">
                <app-footer></app-footer>
            </v-col>
        </v-row>
    </v-row>

</section>
</template>

<script>
import Footer from "../components/public/Footer.vue";
export default {
    props: ['data', 'footer'],
    components:{
        appFooter: Footer
    }
};
</script>

<style>
#textimgarea {
  background: url('/assets/img/bg.png')
    no-repeat center center fixed !important;
  background-size: cover;
}
</style>