import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {
    // Get Settings
    async getSettings({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/settings`, method: 'GET' })
                .then(resp => {
                    localStorage.setItem('settings', JSON.stringify(resp.data))
                    commit('update_settings', resp.data)
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getStats({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/home/stats`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}