<template>
  <div class="footer">
    <v-row
        justify="center"
        style="max-width: 700px"
        class="mx-auto"
    >
      <v-col
          cols="12"
          sm="4"
          align="center"
          class="mb-3"
      >
        <img src="/assets/img/safety/dsgvo-g.svg" height="55">
      </v-col>
      <v-col
          cols="12"
          sm="4"
          align="center"
          class="mb-3"
      >
        <img src="/assets/img/safety/datenspeicher-g.svg" height="55">
      </v-col>
      <v-col
          cols="12"
          sm="4"
          align="center"
      >
        <img src="/assets/img/safety/ssl-g.svg" height="55">
      </v-col>
    </v-row>

    <v-footer
        padless
    >
      <v-row
          justify="center"
          class="footer"
          no-gutters
      >
        <v-col cols="12" sm="4" class="px-10 px-md-0">
          <div class="footer-col">
            <v-list
                flat
                color="#3e8a82"
            >
  <!--            <v-list-item to="/"></v-list-item>-->
              <v-list-item to="/"><span class="font-weight-bold">Geras24</span></v-list-item>
              <v-list-item class="spacer"></v-list-item>
              <v-list-item to="/produkte/persoenliche-botschaft">Persönliche Botschaft</v-list-item>
              <v-list-item to="/produkte/checkliste">Checkliste</v-list-item>
                <v-list-item to="/produkte/tresor">Tresor</v-list-item>
              <v-list-item to="/produkte/der-letzte-wunsch">Der letzte Wunsch</v-list-item>
              <v-list-item href="https://www.up-geras24.de/" target="_blank">Up!</v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col
            cols="12" sm="4"
            align="center"
            class="px-10 px-md-0"
        >
          <div class="footer-col">
            <v-list
                flat
                color="#3e8a82"
            >
              <v-list-item class="spacer"></v-list-item>
              <v-list-item class="spacer"></v-list-item>
              <v-list-item to="/preise">Preise</v-list-item>
              <v-list-item to="/filme">Filme</v-list-item>
              <v-list-item to="/notfallbox">Notfallbox</v-list-item>
              <v-list-item to="/news">News und Presse</v-list-item>
              <v-list-item to="/partner">Partner</v-list-item>
<!--              <v-list-item href="https://sparkasse-duisburg.beratung.io/#/insurance/check?payment_interval=4" target="_blank">Versicherungsvergleich</v-list-item>-->
              <v-list-item  href="https://shop.geras24.de" target="_blank">Geras24 Shop</v-list-item>
<!--              <v-list-item to="/datensicherheit">Datensicherheit</v-list-item>-->
            </v-list>
          </div>

        </v-col>
        <v-col
            cols="12" sm="4"
            align="right"
            class="px-10 px-md-0"
        >
          <div class="footer-col">
            <v-list
                flat
                color="#3e8a82"
            >
              <v-list-item class="spacer"></v-list-item>
              <v-list-item class="spacer"></v-list-item>
              <v-list-item to="/ueber-geras24">Über uns</v-list-item>
              <v-list-item to="/hilfe">Hilfe</v-list-item>
              <v-list-item to="/kontakt">Kontakt</v-list-item>
              <v-list-item to="/impressum">Impressum</v-list-item>
              <v-list-item to="/allgemeine-verkaufsbedingungen">Verkaufsbedingungen</v-list-item>
              <v-list-item to="/datenschutz">Datenschutz</v-list-item>
  <!--            <v-list-item to="/kontakt">Kontakt</v-list-item>-->
              <v-list-item class="spacer"></v-list-item>
  <!--            <v-list-item><span class="white&#45;&#45;text">© Geras24 2022</span></v-list-item>-->
            </v-list>
          </div>
        </v-col>

        <div class="copyright" style="">© {{ year }} Geras24</div>

      </v-row>

  <!--    <v-row-->
  <!--        justify="center"-->
  <!--        class="footer"-->
  <!--        no-gutters-->
  <!--    >-->
  <!--      <v-col-->
  <!--          cols="12"-->
  <!--          sm="4"-->
  <!--          align="center"-->
  <!--          class="mb-2"-->
  <!--      >-->
  <!--        <img src="/assets/img/safety/dsgvo-g.svg" height="50">-->
  <!--      </v-col>-->
  <!--      <v-col-->
  <!--          cols="12"-->
  <!--          sm="4"-->
  <!--          align="center"-->
  <!--          class="mb-2"-->
  <!--      >-->
  <!--        <img src="/assets/img/safety/datenspeicher-g.svg" height="50">-->
  <!--      </v-col>-->
  <!--      <v-col-->
  <!--          cols="12"-->
  <!--          sm="4"-->
  <!--          align="center"-->
  <!--      >-->
  <!--        <img src="/assets/img/safety/ssl-g.svg" height="50">-->
  <!--      </v-col>-->
  <!--    </v-row>-->

      <v-btn
          v-if="!$store.getters.isLoggedIn"
          elevation="12"
          fixed
          bottom
          right
          text
          @click="showRegister = true"
          class="btn-default btn-blue"
          style="z-index: 2"
      >Kostenlos<br />registrieren</v-btn>

    </v-footer>

    <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
    <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

    <cookie-banner></cookie-banner>

  </div>

</template>

<script>

import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";
import CookieBanner from "../../widgets/CookieBanner.vue";

export default {
  components:{
    LoginDialog,
    RegisterDialog,
    CookieBanner
  },
  props:[],
  data() {
    return {
      showLogin: false,
      showRegister: false,
      year: ''
    }
  },
  mounted() {
    this.getYear()
  },
  methods: {
    getYear() {
      let d = new Date();
      this.year = d.getFullYear()
    }
  }
};
</script>