import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {
    async getMedias({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/medias/${data.collection}/${data.type}${data.category ? ('/'+data.category) : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteMedia({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/medias/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getConfidingMedias({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/medias/accessed/${data.collection}/${data.truster_id}${data.type ? ('/'+data.type) : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getMediaTrustedUsers({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/medias/${id}/accessedUsers`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async setMediaTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/medias/${data.id}/access`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteMediaTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/medias/${data.id}/removeAccess`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getImage({ commit }, link) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/files/${link}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}