<template>
    <section>
        <v-img :src="img" width="100%" height="550">
          <v-overlay absolute>
            <div class="d-flex fill-height" style="flex-direction:column">
              <div class="d-flex fill-height align-center justify-center">
                <v-card flat color="transparent">
                  <v-card-text class="text-in-image">
                    „Das erste, das der Mensch im Leben vorfindet, das letzte, wonach er die Hand ausstreckt, das kostbarste, was er im Leben besitzt,
                    ist die Familie.“
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-overlay>
        </v-img>
    </section>
</template>

<script>

export default {
    props:['img']
};
</script>