<template>
<section>
    <v-container class="text-container">
        <v-row>
          <v-col
              cols="12"
              :md="cols[0]"
              justify="center"
              :order="imageCol === '1' ? 2 : 1"
              :class="imageCol === '1' ? 'mt-10 mt-md-0' : ''"
          >
            <h1>{{ title }}</h1>
            <div class="main-font" v-html="text"></div>
            <div class="pt-5">
              <v-btn
                  v-if="button"
                  elevation="5"
                  class="btn-default"
                  min-width="0"
                  text
                  :href="link"
                  :target="target"
              >
                <span>{{ button }}</span>
              </v-btn>
            </div>
          </v-col>
          <v-col
              cols="12"
              :md="cols[1]"
              align="center"
              justify="center"
              :order="imageCol === '1' ? 1 : 2"
              :class="imageCol === '2' ? 'mt-10 mt-md-0' : ''"
          >
            <v-img v-if="image" :src="image" :width="imageWidth" ></v-img>
          </v-col>
        </v-row>
    </v-container>
</section>
</template>

<script>
export default {
    props: ['cols', 'imageCol', 'title', 'text', 'image', 'imageWidth', 'button', 'link', 'target']
};
</script>