<template>
<!--    <div class="row">-->
        <v-container>
          <v-row class="my-10 main-font">
            <v-col cols="12" md="4" sm="6" v-for="(item, i) in data" :key="i">
              <v-hover
                  v-slot="{ hover }"
              >
                <v-card
                    :elevation="hover ? 12 : 4"
                    class="mx-auto"
                    :href="item.route"
                    :target="item.route.includes('up-geras24') ? '_blank' : ''"
                >
                  <v-img
                      class="white--text align-end"
                      :src="item.photo"
                      height="350"
                  >
<!--                    <div class="price-title">{{ plan.name }}</div>-->
                  </v-img>

                  <v-card-text class="text--primary text-center bg-turquoise">
                    <div class="home-box">{{ item.title1 }}</div>

<!--                    <div class="duration">pro {{ plan.duration ? plan.duration + ' Tage' : 'Jahr' }}</div>-->
                  </v-card-text>

                </v-card>

              </v-hover>
            </v-col>
          </v-row>

<!--            <div v-for="(item, i) in data" :key="i" style="background-color: #fff" :class="`col-12 col-sm-6 col-md-${col}`" class="align-center justify-center">-->
<!--              <a :href="item.route">-->
<!--                <v-row class="menu-image" align="center" justify="center">-->
<!--                      <img-->
<!--                          :src="item.photo"-->
<!--                          style="height: 100%; width: 100%;"-->
<!--                      >-->
<!--                      <a class="menu-title1" :class="{'text-subtitle-1': $helpers.isMobile()}" :href="item.route"><span>{{ item.title1 }}</span></a>-->
<!--                  </v-row>-->
<!--              </a>-->
<!--            </div>-->
        </v-container>
<!--    </div>-->
</template>

<script>
export default {
    data(){
        return {
            dItem: {
                title1: null,
                title2: null,
                route: null,
            }
        }
    },
    props: ['col', 'data'],
    methods:{
        openDialog(item) {
            this.dItem = item
        }
    }
};
</script>