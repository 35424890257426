import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetTrustedUsers({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/trustedUsers`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetTrustedGroups({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/trustedGroups`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetCertificateUploaders({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/certificateUploaders`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUseraddCertificateUploader({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/certificateUploaders`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteCertificateUploader({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/certificateUploaders`, data: data, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsersearchUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trustedUsers/search?q=${data.value}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUseraddTrustedUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trustedUsers`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteTrustedUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trustedUsers/remove`, data: data, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUseraddTrustedGroup({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trustedGroups`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteTrustedGroup({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/trustedGroups/${data.id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}