<template>
    <section style="background-color: white">
        <video-player
            style="background-color: white"
            class="video-player-box vjs-big-play-centered"
            ref="videoPlayer"
            :options="playerOptions"
            preload="auto"
            :playsinline="true">
        </video-player>
    </section>
</template>

<script>

export default {
    data(){
        return {
            play: true,
            playerOptions: {
                // videojs options
                muted: false,
                language: 'de',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [{
                    // type: "video/mp4",
                    src: this.src
                }],
                fluid: true,
                poster: this.cover
            }
        }
    },
    props: ['src', 'cover'],
    mounted() {
      // if(this.autoPlay) {
      //   setTimeout(() => {
      //     this.playVideo()
      //     console.log('jup')
      //   }, 5000)
      // }
    },
    methods: {
        playVideo() {
            this.play = !this.play;

        },
        // listen event
        onPlayerPlay(player) {
            // console.log('player play!', player)
        },
        onPlayerPause(player) {
            // console.log('player pause!', player)
        },
        // ...player event

        // or listen state event
        playerStateChanged(playerCurrentState) {
            // console.log('player current update state', playerCurrentState)
        },

        // player is ready
        playerReadied(player) {
            console.log('the player is readied', player)
            // you can use it to do something...
            // player.[methods]
        }
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      }
    },
};
</script>

<style>
.section-video:after {
        position:absolute;
        z-index:10000000;
        content: url('http://www.clker.com/cliparts/K/7/2/z/b/U/media-hi.png');
}
</style>