import axios from 'axios'
import Vue from 'vue'

export default {
    async adminGetPays({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/pays?page=${data.page}${data.to ? '&to='+ data.to : ''}${data.from ? '&from='+ data.from : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}