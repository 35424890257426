<template>
  <v-carousel
      height="calc(100vh - 90px)"
      :cycle="true"
      :show-arrows="false"
      hide-delimiters
  >
    <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="scroll-x-transition"
        transition="scroll-x-transition"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
    props:['items']
};
</script>