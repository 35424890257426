import axios from 'axios'
import Vue from 'vue'

export default {
    async adminGetChecklists({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/checklists`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetChecklist({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/checklists/${id}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminCreateChecklists({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/checklists`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdateChecklist({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/checklists/${data.id}`, data: data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteChecklist({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/checklists/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}