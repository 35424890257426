import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetCategories({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/fileCategories/${data.type}${data.type == 'vault' ? '' : '/wunsch'}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserstoreCategories({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/fileCategories/${data.type}${data.type == 'vault' ? '' : '/wunsch'}`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserupdateCategories({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/fileCategories/${data.id}`, data: data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteCategories({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/fileCategories/${data.id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}