import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetMedias({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/medias/${data.collection}/${data.type}${data.category ? ('/'+data.category) : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteMedia({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/medias/${data.id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetConfidingMedias({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/medias/accessed/${data.collection}/${data.truster_id}${data.type ? ('/'+data.type) : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetMediaTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/medias/${data.id}/accessedUsers`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsersetMediaTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/medias/${data.id}/access`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteMediaTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/medias/${data.id}/removeAccess`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}