import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetTickets({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/tickets`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminUsergetTicket({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/tickets/${data.id}`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminUsergetTicketsForm({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/tickets/form`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminUsersendTicket({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/tickets`, data: data, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminUsersendTicketComment({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/tickets/${data.ticket_id}/comments`, data: data, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}