<template>
    <section>
        <v-img :src="img" width="100%" height="600"></v-img>
    </section>
</template>

<script>

export default {
    props:['img']
};
</script>