import axios from 'axios'
import { data } from 'jquery'
import Vue from 'vue'
import store from '../../index'

export default {
    // Login User
    async login({ commit }, data) {

        return new Promise((resolve, reject) => {
            // commit('auth_request')
            axios({ url: '/login', data: data, method: 'POST' })
            .then(resp => {
                if (!resp.action){
                    const token = resp.data.token
                    const user = resp.data
                    localStorage.setItem('token', token)
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ token
                    commit('auth_success', user)
                }
                resolve(resp.data)
            })
            .catch(err => {
                commit('auth_error')
                reject(err)
            })
        })
    },

    // Login With Provider
    async providerLogin({ commit }, provider) {

        return new Promise((resolve, reject) => {
            axios({ url: `/login/${provider}`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async providerLoginCallback({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/login/${data.provider}/callback?${data.data}`, method: 'GET' })
            .then(resp => {
                const token = resp.data.token
                const user = resp.data
                localStorage.setItem('token', token)
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ token
                commit('auth_success', user)
                resolve(resp.data)
            })
            .catch(err => {
                commit('auth_error')
                reject(err)
            })
        })
    },

    // Register User
    async register({ commit }, user) {

        return new Promise((resolve, reject) => {
            axios({ url: '/register', data: user, method: 'POST' })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async resetPassword({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: '/forgot-password', data: data, method: 'POST' })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async setNewPassword({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: '/reset-password', data: data, method: 'POST' })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    // Verify Email
    async verifyEmail({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/email/verify?${data}`, method: 'POST' })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    // Logout User
    async logout({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: store.getters.user.user.role == 'Owner' ? '/logout' : '/admin/logout', method: 'POST' })
            .then(resp => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async getOtpSecret({ commit }, token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+ token
        return new Promise((resolve, reject) => {
            axios({ url: `/otp/secret`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async verifyOtp({ commit }, data) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer '+ token
        return new Promise((resolve, reject) => {
            axios({ url: `/otp/verify`, data: data, method: 'POST' })
            .then(resp => {
                const token = resp.data.token
                const user = resp.data
                localStorage.setItem('token', token)
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ token
                commit('auth_success', user)
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async loginOtp({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+ data.token
        return new Promise((resolve, reject) => {
            axios({ url: `/otp/login`, data: data, method: 'POST' })
            .then(resp => {
                const token = resp.data.token
                const user = resp.data
                localStorage.setItem('token', token)
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ token
                commit('auth_success', user)
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },



}