import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetDocuments({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/documents/${data.collection}${data.category ? ('/'+ data.category) : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteDocument({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/documents/${data.id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetConfidingDocuments({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/documents/accessed/${data.collection}/${data.truster_id}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetDocumentTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/documents/${data.id}/accessedUsers`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsersetDocumentTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/documents/${data.id}/access`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserdeleteDocumentTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/documents/${data.id}/removeAccess`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}