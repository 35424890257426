import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetPlans({ commit }, user_id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/plans`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetPlanSubsRequest({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/plans/${data.id}/subscribe/${data.method}`, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetPaymentVerify({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/subscriptions/${data.id}/${data.status}?${data.params}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}