import axios from 'axios'
import Vue from 'vue'

export default {
    async adminUsergetMe({ commit }, user_id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/me`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUsergetInvideCode({ commit }, user_id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${user_id}/dashboard/user/referralToken`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserupdateProfile({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/user/profile`, data: data.data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUseruploadAvatar({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/user/avatar`, data: data.data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUserupdatePassword({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.user_id}/dashboard/user/password`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}