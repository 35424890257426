import axios from 'axios'
import Vue from 'vue'

export default {
    async adminGetDocumenttemps({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/documentTemplates`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminCreateDocumenttemps({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/documentTemplates`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteDocumenttemp({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios({ url: `/admin/documentTemplates/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}