import Vue from "vue";
import JsEncrypt from 'jsencrypt/bin/jsencrypt';
import store from '../store/index'

export default {
    isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },

    showError(err) {
        try {
            if (err.response.status === 422) {
                for (const key in err.response.data.errors) {
                    if (Object.hasOwnProperty.call(err.response.data.errors, key)) {
                        const error = err.response.data.errors[key];
                        Vue.$toast.error(error[0])
                    }
                }
            }
            else if (err.response.status === 403) Vue.$toast.error('Access Denied!')
            else Vue.$toast.error(err.response.data.message || 'Fehler!')
        } catch (error) {
            Vue.$toast.error('Error!')
        }
        
    },

    showMessage(type, msg) {
        if (type == 'success') Vue.$toast.success(msg)
        else if (type == 'error') Vue.$toast.error(msg)
        else if (type == 'warning') Vue.$toast.warning(msg)
        else if (type == 'info') Vue.$toast.info(msg)
        else Vue.$toast.default(msg)
    },

    generatePassword(length) {
        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        let retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    },
    
    prvKeyValidator(pub_key, prv_key){
        try {
            // RSA object
            let encryptor = new JsEncrypt();
            let decrypt= new JsEncrypt();

            encryptor.setPublicKey(pub_key);
            decrypt.setPrivateKey(prv_key);

            let randomString  = this.generatePassword(20)
            let encryptedData = encryptor.encrypt(randomString);
            let decryptedData = decrypt.decrypt(encryptedData);

            if (randomString == decryptedData) return true
            else return false

        } catch (error) {
            console.log(error);
            return false
        }
    },

    checkPermission(permission){
        for (let index = 0; index < store.getters.user.user.permissions.length; index++) {
            const per = store.getters.user.user.permissions[index];
            if (per.name === permission) return true
        }
        return false
    },

    setMetaTags() {
        // let route = new VueRouter;
        // console.log(route.$route)
        // document.title = this.$route.meta.title;
        //
        // const descEl = document.querySelector('head meta[name="description"]');
        // descEl.setAttribute('content', this.$route.meta.description);
    }
}