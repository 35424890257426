import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {
    async getLastWishData({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/last-wish`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async saveLastWishData({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/last-wish`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async generateLastWishPdf({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/last-wish/generate-pdf`, method: 'GET', responseType: 'blob' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}