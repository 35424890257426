import axios from 'axios'
import Vue from 'vue'

export default {
    async adminGetEmails({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetEmail({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates/${id}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSetEmailTemplate({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdateEmailTemplate({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates/${data.id}`, data: data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteEmailFile({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates/files/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUploadEmailTemplateFile({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates/${data.id}/files`, data: data.data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteEmail({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emailTemplates/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetEmailsSents({ commit }, page) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/sentEmails?page=${page}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSendEmail({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/emails/send`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}