import axios from 'axios'
import Vue from 'vue'

export default {
    async adminGetMe({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/me`, method: 'GET' })
                .then(resp => {
                    commit('update_user', resp.data)
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetUsers({ commit }, page) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users?page=${page}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSearchUser({ commit }, value) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/search?q=${value}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdateProfile({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: '/dashboard/user/profile', data: data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUploadAvatar({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: '/dashboard/user/avatar', data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdatePassword({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: '/dashboard/user/password', data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSendNotifications({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: '/admin/send', data: data, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminGetNotifactions({ commit }, page) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/notifications?page=${page}`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminGetUserTrash({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.id}/trashed?page=${data.page}`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminRestoreUserTrash({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/files/${id}/restore`, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async adminUpdateUserStatus({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/users/${data.id}/status`, data: data, method: 'PUT' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}