import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const theme = {
    primary: '#3e8a82',
    succes: '#3e8a82',
    secondary: '#9C27b0',
    accent: '#9C27b0',
    info: '#00CAE3',
    lightGreen: '#70b197',
}

export default new Vuetify({
    // lang: {
    //   t: (key, ...params) => i18n.t(key, params),
    // },
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    },
})