import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {
    async getMe({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}/me` : (store.getters.isLoggedIn ? (store.getters.user.user.role == 'Owner' ? '/me' : '/admin/me') : '/me') }`, method: 'GET' })
                .then(resp => {
                    commit('update_user', resp.data)
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getInvideCode({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/referralToken`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async updateProfile({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/profile`, data: data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async uploadAvatar({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/avatar`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async updatePassword({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/password`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async setPubKey({ commit }, data) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/key`, data: data, method: 'POST' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async setPrvKey({ commit }, data) {
        if (!store.getters.secondUser) axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.tresorToken

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/key/p`, data: data, method: 'POST' })
                .then(resp => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    resolve(resp.data)
                })
                .catch(err => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ store.getters.token
                    reject(err)
                })
        })
    },

    async getRequestToRevokeKeys({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/key`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async updateKeys({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/key?verify_key=${data.verifyKey}`, data: data.data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getVerifyKey({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/key/verify`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async sendInviteEmail({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/invite`, data: data, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async getUserDuplications({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/duplications`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async searchAllUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/user/search?q=${data}`, method: 'GET' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async addDuplicationUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/plans/duplicate`, data: data, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async disableOtp({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/otp/disable`, data: data, method: 'POST' })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}