<template>
    <section>
        <Header v-if="!$route.meta.authOnly" @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

      <div v-if="$route.name == 'home'">
<!--        <v-container>-->
          <v-row
              align="center"
          >
            <v-col
                cols="12"
                md="6"
                align="center"
                justify="center"
                class="header-col"
            >
              <div class="header-text">
                <div>
                  <h3 class="font-italic">Bekannt aus den Medien</h3>
<!--                  <v-row class="mt-3">-->
                  <v-row class="my-5">
                    <v-col md="4">
                      <a href="https://unternehmen.focus.de/digitale-lebensordnung.html" target="_blank"><v-img min-width="110" src="assets/img/icons/focus.svg" width="110"></v-img></a>
                    </v-col>
                    <v-col md="8" class="my-auto ml-auto d-flex align-items-center">
                      <a href="https://unternehmen.focus.de/digitale-lebensordnung.html" target="_blank" class="dark">Zum Artikel</a>
                    </v-col>
                  </v-row>
<!--                  <v-row class="mb-5">-->
<!--                    <v-col md="4">-->
<!--                      <a href="https://www.qvc.de/GERAS24-Notfall-Set-fuer-lebensrettende-Dokumente-2x-Box-%26-Ordner.product.844191.html" target="_blank"><v-img min-width="110" src="assets/img/icons/qvc.svg" width="110"></v-img></a>-->
<!--                    </v-col>-->
<!--                    <v-col md="8" class="my-auto ml-auto d-flex align-items-center">-->
<!--                      <a href="https://www.qvc.de/GERAS24-Notfall-Set-fuer-lebensrettende-Dokumente-2x-Box-%26-Ordner.product.844191.html" target="_blank" class="dark">Zum Video</a>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
                </div>

                <h1>Geras24 – der digitale Ort zur Absicherung Deines Lebens und der Familie</h1>
                <div class="main-font">
                  {{ $t('texts.home.introDescription') }}
                </div>
                <div class="pt-5">
                  <v-btn
                      v-if="!$store.getters.isLoggedIn"
                      elevation="5"
                      class="btn-default"
                      min-width="0"
                      text
                      @click="showRegister = true"
                  >
                    <span>Kostenlos<br>registrieren</span>
                  </v-btn>
                  <v-btn
                      elevation="5"
                      class="ml-2 btn-default"
                      min-width="0"
                      text
                      @click="$router.push({name: 'product', params: {product: 'checkliste'}})"
                  >
                    <span>Kostenlose<br>Checkliste</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
                cols="12"
                md="6"
                class="hidden-sm-and-down"
            >
              <ImageSlider
                :items="[
                  {
                    src: 'assets/img/home/slider_1.jpg',
                  },
                  {
                    src: 'assets/img/home/slider_2.jpg',
                  },
                  {
                    src: 'assets/img/home/slider_3.jpg',
                  },
                  {
                    src: 'assets/img/home/slider_4.jpg',
                  },
                  {
                    src: 'assets/img/home/slider_5.jpg',
                  }
                ]"
              >

              </ImageSlider>
            </v-col>
          </v-row>
<!--      </v-container>-->

        <TextWithImage
            :cols="[7,5]"
            image-col="2"
            title="Geras24 – unsere Vision ist, dass alle digitalen und verantwortungsvollen Menschen Geras24 nutzen"
            :text="$t('texts.home.section1Description')"
            image="assets/img/home/geras1.png"
            image-width="460"
            button="Mehr erfahren"
            link="/ueber-geras24"
        >
        </TextWithImage>

        <ImageMenu v-if="$route.name == 'home'" :data="menus" :col="4" />

        <v-container class="social-container">
          <v-row align="center">
            <v-col cols="12" sm="3" align="center" justify="center" class="mb-10 mb-sm-0">
              <div class="mb-4"><a href="https://www.facebook.com/Geras24online/" target="_blank"><v-img min-width="26" src="assets/img/icons/facebook.svg" width="26"></v-img></a></div>
              <div><a href="https://www.facebook.com/Geras24online/" class="dark non-decoration" target="_blank"><div class="h-medium">Facebook</div></a></div>
            </v-col>
            <v-col cols="12" sm="3" align="center" justify="center" class="mb-10 mb-sm-0">
              <div class="mb-4"><a href="https://www.instagram.com/geras24.de/?utm_source=qr" target="_blank"><v-img min-width="26" src="assets/img/icons/insta.svg" width="26"></v-img></a></div>
              <div><a href="https://www.instagram.com/geras24.de/?utm_source=qr" class="dark non-decoration" target="_blank"><div class="h-medium">Instagram</div></a></div>
            </v-col>
            <v-col cols="12" sm="3" align="center" justify="center" class="mb-10 mb-sm-0">
              <div class="mb-4"><a href="https://www.youtube.com/c/Geras24" target="_blank"><v-img min-width="36" src="assets/img/icons/youtube.svg" width="36"></v-img></a></div>
              <div><a href="https://www.youtube.com/c/Geras24" class="dark non-decoration" target="_blank"><div class="h-medium">Youtube</div></a></div>
            </v-col>
            <v-col cols="12" sm="3" align="center" justify="center" class="mb-10 mb-sm-0">
              <div class="mb-4"><a href="https://www.tiktok.com/@geras24_?_t=8kTnOzhlo12&_r=1" target="_blank"><v-img min-width="36" src="assets/img/icons/tiktok-green.svg" width="36"></v-img></a></div>
              <div><a href="https://www.tiktok.com/@geras24_?_t=8kTnOzhlo12&_r=1" class="dark non-decoration" target="_blank"><div class="h-medium">TikTok</div></a></div>
            </v-col>
          </v-row>
        </v-container>

        <TextWithImage
            :cols="[8,4]"
            image-col="1"
            title="Geras24 – wir sichern dich und deine Familie ab"
            :text="$t('texts.home.section2Description')"
            image="assets/img/home/geras2.jpg"
            image-width="320"
            button="Versicherungsvergleich"
            link="/versicherungsvergleich"
        >
        </TextWithImage>

        <WideImageWithText v-if="$route.name == 'home'" :img="image"/>

        <TextWithImage
            :cols="[8,4]"
            image-col="2"
            title="Geras24 – Unsere Leistungen im Überblick"
            :text="$t('texts.home.section3Description')"
            image=""
            button="Preisübersicht"
            link="/preise"
        >
        </TextWithImage>

        <WideImage v-if="$route.name == 'home'" :img="image2"/>

        <TextWithImage
            :cols="[7,5]"
            image-col="2"
            title="Heute schon an morgen denken und den Tag danach"
            :text="$t('texts.home.section4Description')"
            image="assets/img/home/geras3.png"
            image-width="230"
            button=""
        >
        </TextWithImage>

        <Footer></Footer>

        <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
        <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>
      </div>

      <v-btn
          v-if="$store.getters.isLoggedIn"
          elevation="5"
          large
          fab
          fixed
          bottom
          right
          class="btn-support"
          @click="$router.push({name: 'support'})"
      >
          <v-icon color="white">mdi-message-reply-text</v-icon>
      </v-btn>

        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </section>
</template>

<script>
import Header from "../Header.vue";
import TextArea from "../../../widgets/TextArea.vue";
import ImageMenu from "../../../widgets/ImageMenuNew.vue";
import WideImage from "../../../widgets/WideImage.vue";
import WideImageWithText from "../../../widgets/WideImageWithText.vue";
import WideVideo from "../../../widgets/WideVideoHeader.vue";
import ImageSlider from "../../../widgets/ImageSlider.vue";
import TextWithImage from "../../../widgets/TextWithImage.vue";
import Footer from "../../../components/public/Footer.vue";
import LoginDialog from "../../../widgets/LoginDialog.vue";
import RegisterDialog from "../../../widgets/RegisterDialog.vue";

// let LoadingComponent;
// let ErrorComponent;
//
// const WideVideo = () => ({
//   // The component to load (should be a Promise)
//   component: import("../../../widgets/WideVideoHeader.vue"),
//   // A component to use while the async component is loading
//   loading: WideVideo,
//   // A component to use if the load fails
//   error: WideVideo,
//   // Delay before showing the loading component. Default: 200ms.
//   delay: 10000,
//   // The error component will be displayed if a timeout is
//   // provided and exceeded. Default: Infinity.
//   timeout: 3000
// })

export default {
    components:{
        Header,
        TextArea,
        ImageMenu,
        WideImage,
        WideImageWithText,
        WideVideo,
        ImageSlider,
        TextWithImage,
        Footer,
        LoginDialog,
        RegisterDialog
    }, 
    data(){
        return {
            payload_1: {
                title: this.$store.getters.settings.txt_area_1_title || '',
                content: this.$store.getters.settings.txt_area_1_content || '',
                icon: '/assets/img/icons/brid.svg',
                photo: null,
                images: null,
                title2: null,                                
                margin_top: '5px',
                padding: '40px 0 40px'
            },
            payload_2: {
                title: this.$store.getters.settings.txt_area_2_content || '',
                content: null,
                icon: null,
                photo: null,
                images: null,
                title2: null,                                
                margin_top: '0',
                padding: '40px 0 40px'
            },
            payload_3: {
                title: this.$store.getters.settings.txt_area_3_title || '',
                content: this.$store.getters.settings.txt_area_3_content || '',
                icon: null,
                photo: '',
                images: null,
                title2: null,                                
                margin_top: '11px',
                padding: '40px 0 100px'
            },
            payload_4: {
                title: this.$store.getters.settings.txt_area_4_title || '',
                content: this.$store.getters.settings.txt_area_4_content || '',
                icon: null,
                images: null,
                title2: this.$store.getters.settings.top_footer || '',
                margin_top: '11px',
                padding: '40px 0 0'
            },
            menus: [],
            image: this.$store.getters.settings.family1Image || '',
            image2: this.$store.getters.settings.family2Image || '',

            plans: null,
            features: [],
            showLogin: false,
            showRegister: false
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted(){
        let menu = []
        let ids = []
        let settings = this.$store.getters.settings
        for (const key in settings) {
            if (Object.hasOwnProperty.call(settings, key)) {
                if (key.slice(0, 9) == 'menuImage' && !ids.includes(key)){ // If Not in ids
                    let splitKey = key.split('_')
                    let id = splitKey[1]
                    ids.push(`menuImage_${id}_img`)
                    ids.push(`menuImage_${id}_t1`)
                    ids.push(`menuImage_${id}_t2`)
                    ids.push(`menuImage_${id}_route`)

                    menu.push({
                        photo: this.getSetting(settings, `menuImage_${id}_img`),
                        title1: this.getSetting(settings, `menuImage_${id}_t1`),
                        title2: this.getSetting(settings, `menuImage_${id}_t2`),
                        route: this.getSetting(settings, `menuImage_${id}_route`),
                        params: {}
                    })
                }
            }
        }
        this.menus = menu
        this.getFeatures()
    },

    methods:{
        getSetting(resp, key){
			for (const k in resp) {
				if (Object.hasOwnProperty.call(resp, k)) {
					const value = resp[k];
					if (k == key) return value
				}
			}
		},

        getFeatures() {
			this.$store.dispatch("getFeaturesGuest")
            .then((resp) => {
                for (let index = 0; index < resp.length; index++) {
                    const item = resp[index];
                    if (item.code != 'tasks.count' && item.code != 'trusted.count')
                        this.features.push(item)
                }
                this.getPlans()
			})
		},

        getPlans(){
            this.$store.dispatch('getPlansGuest')
            .then(resp => {
                this.plans = resp
            })
        },

        getPlanFeature(plan_id, code){
            let result = false
            for (let index = 0; index < this.plans.length; index++) {
                const plan = this.plans[index];
                if(plan.id == plan_id){
                    for (let k = 0; k < plan.features.length; k++) {
                        const feature = plan.features[k];
                        if (code == feature.code && feature.type != 'limit') result = true
                        else if (code == feature.code) {
                           if (code == 'files.size') result = feature.limit+ ' MB'
                           else result = feature.code == 'duplicate_subscription' ? feature.limit + 1 : feature.limit
                        }
                    }
                }
            }
            return result
        }
    }

}
</script>