<template>
  <v-row justify="center">
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          {{ $t('login') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close-login-modal')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="auth-form">
            <v-row class="mt-8">
              <v-text-field
                  :label="$t('username_or_email')"
                  :disabled="loading"
                  filled
                  rounded
                  :name="Math.random()"
                  v-model="form.username"
                  @keyup.enter="login"
              ></v-text-field>
            </v-row>
            <v-row class="mt-4">
              <v-text-field
                  :label="$t('password')"
                  type="password"
                  :disabled="loading"
                  filled
                  rounded
                  :name="Math.random()"
                  v-model="form.password"
                  @keyup.enter="login"
              ></v-text-field>
            </v-row>
            <v-row class="mt-2" justify="center">
              <v-col>
                <v-btn
                    elevation="5"
                    :loading="loading || (providerLoading ? true : false)"
                    :disabled="loading || (providerLoading ? true : false)"
                    color="turquoise"
                    block
                    @click="login"
                >
                  Login
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="showVerificationLink">
              <v-col>
                <v-card class="pa-3 primary-text">
                  Sie haben Ihre E-Mail-Adresse noch nicht verifiziert.<br>
                  Wir haben Ihnen eine neue E-Mail mit dem Bestätigungslink zugesendet.
                </v-card>
                <div>
                  <a href="">E-Mail senden</a>
                </div>
              </v-col>
            </v-row>

<!--            <v-row class="mt-4" justify="center">-->
<!--              <v-col>-->
<!--                <v-btn-->
<!--                    color="error"-->
<!--                    dark-->
<!--                    block-->
<!--                    rounded-->
<!--                    @click="loginWithProvider('google')"-->
<!--                    :disabled="providerLoading ? true : false"-->
<!--                    :loading="providerLoading == 'google' ? true : false"-->
<!--                >-->
<!--                  {{ $t('google') }}-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--              <v-col>-->
<!--                <v-btn-->
<!--                    color="primary"-->
<!--                    dark-->
<!--                    block-->
<!--                    rounded-->
<!--                    @click="loginWithProvider('facebook')"-->
<!--                    :disabled="providerLoading ? true : false"-->
<!--                    :loading="providerLoading == 'facebook' ? true : false"-->
<!--                >-->
<!--                  {{ $t('facebook') }}-->
<!--                </v-btn>-->
<!--              </v-col>-->

<!--            </v-row>-->
            <v-row class="pt-4">
              <v-col justify="center" align="center">
<!--                <p><router-link :to="{name: 'register'}" class="primary&#45;&#45;text">{{ $t('create_account') }}</router-link></p>-->
<!--                <p><router-link :to="{name: 'resetPassword'}" class="primary&#45;&#45;text">{{ $t('forget_password') }}</router-link></p>-->
                <p>{{ $t('no_account') }} <a href="#" @click.prevent="closeAndOpenRegisterModal()" class="primary--text">{{ $t('create_account_for_free') }}</a></p>
                <p><router-link :to="{name: 'resetPassword'}" class="primary--text">{{ $t('forget_password') }}</router-link></p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
<!--        <v-card-actions>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn-->
<!--              color="blue darken-1"-->
<!--              text-->
<!--              @click="dialog = false"-->
<!--          >-->
<!--            Close-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--              color="blue darken-1"-->
<!--              text-->
<!--              @click="dialog = false"-->
<!--          >-->
<!--            Save-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";

export default {
  props: ['value'],
  data(){
    return {
      loading:false,
      form: {
        username: null,
        password: null,
      },
      providerLoading: false,
      dialog: false,
      showVerificationLink: false
    }
  },
  mounted(){
    if (this.$route.name == 'authVerify') {
      // Get Parameters
      let finalQuery = null
      for (const key in this.$route.query) {
        if (Object.hasOwnProperty.call(this.$route.query, key)) {
          const value = this.$route.query[key];
          finalQuery = (finalQuery ? (finalQuery + '&') : '') + key + '=' + value
        }
      }
      // Verify Route
      this.loading = true
      this.$store.dispatch('verifyEmail', finalQuery)
          .then(resp => {
            Vue.$toast.success(this.$t('success_verify'))
            if (this.$route.query.plan_id) this.$router.push({name: 'login', params:{ id: this.$route.query.plan_id }})
            else this.$router.push({name: 'login'})
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    }
    else if (this.$route.name == 'providerCallback'){
      // Get Parameters
      let finalQuery = null
      for (const key in this.$route.query) {
        if (Object.hasOwnProperty.call(this.$route.query, key)) {
          const value = this.$route.query[key];
          finalQuery = (finalQuery ? (finalQuery + '&') : '') + key + '=' + value
        }
      }
      // Verify Route
      this.loading = true
      this.$store.dispatch('providerLoginCallback', {data: finalQuery, provider: this.$route.params.provider})
          .then(resp => {
            Vue.$toast.success(this.$t('success_login'))
            this.otpStatus = resp.user.otp_status

            if (this.otpStatus == 'disabled'){
              if (this.$route.query.redirect)
                window.location = this.$route.query.redirect
              else
                this.$router.go({name: 'home'})
            }
            else this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    }
  },
  methods: {
    login(){
      if (!this.form.username){
        Vue.$toast.error(this.$t('enter_username'))
        return
      }
      if (!this.form.password){
        Vue.$toast.error(this.$t('enter_password'))
        return
      }
      this.loading = true
      this.$store.dispatch('login', this.form)
          .then(resp => {
            if(resp.result === false) {
              this.showVerificationLink = true
              this.loading = false
            } else {
              this.showVerificationLink = false

              this.form = {
                username: null,
                password: null,
              }
              this.action = resp.action || null

              if (this.action == 'REGISTER_OTP' || this.action == 'REQUIRE_OTP'){
                this.$router.push({name: 'otp', params:{action: resp, redirect: this.$route.query.redirect || null, id: this.$route.params.id || null}})
              } else {
                Vue.$toast.success(this.$t('success_login'))
                // window.location.href = '/dashboard/profile/info'
                // this.$router.go({name: 'profile'})
                // console.log(this.$route)
                if (this.$route.query.redirect)
                  window.location = this.$route.query.redirect
                else if (this.$route.params.id)
                  this.$router.push({ name: 'profilePlans', params:{ id: this.$route.params.id }})
                else
                  this.$router.push({name: 'profile'})
              }
            }
          })
          .catch(err => {
            this.loading= false
            this.$helpers.showError(err)
          })
    },

    loginWithProvider(provider){
      this.providerLoading = provider
      this.$store.dispatch('providerLogin', provider)
          .then(resp => {
            this.$helpers.showMessage('info', this.$t('redirect_to_provider'))
            window.location.href = resp.url
          })
          .catch(err => {
            this.providerLoading= false
            this.$helpers.showError(err)
          })
    },
    closeAndOpenRegisterModal() {
      this.$emit('close-login-modal')
      this.$emit('open-register-modal')
    }
  }
}

</script>