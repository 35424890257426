import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {
    async getDocuments({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/documents/${data.collection}${data.category ? ('/'+ data.category) : ''}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteDocument({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/documents/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getConfidingDocuments({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/documents/accessed/${data.collection}/${data.truster_id}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getDocumentTrustedUsers({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/documents/${id}/accessedUsers`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async setDocumentTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/documents/${data.id}/access`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteDocumentTrustedUsers({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `${ store.getters.secondUser ? `/admin/users/${store.getters.secondUser}` : '' }/dashboard/documents/${data.id}/removeAccess`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}