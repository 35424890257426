export default {
    login: "Login",
    username_or_email: "Email-Adresse",
    password: "Passwort",
    safety: "Sicherheit",
    google: "Google",
    facebook: "Facebook",
    create_account: "Registrieren",
    no_account: "Noch kein Account? ",
    create_account_for_free: "Kostenlos registrieren",
    for_personal_or_business: "für privat oder geschäftlich",
    forget_password: "Passwort vergessen?",
    success_verify: "Ihr Account wurde erfolgreich verifiziert! Sie können sich jetzt einloggen!",
    success_login: "Willkommen bei Geras!",
    enter_username: "Bitte geben Sie den Benutzernamen oder Ihre Email ein!",
    enter_password: "Bitte geben Sie Ihr Passwort ein!",
    redirect_to_provider: "Weiterleitung zur Anbieterseite ...",    
    register: "Registrieren",
    name: "Name",
    first_name: "Vorname",
    last_name: "Nachname",
    username: "Benutzername",
    email: "Email",
    password_confirm: "Passwort bestätigen",
    referral_code: "Referenzcode",
    with_your_account: "mit Ihrem Konto",
    enter_firstname: "Bitte geben Sie Ihren Vornamen ein!",
    enter_name: "Bitte geben Sie Ihren Nachnamen ein!",
    enter_email: "Bitte geben Sie Ihre Email ein!",
    enter_password_confirm: "Bitte Passwort bestätigen!",
    accept_terms: "Bitte Datenschutz bestätigen!",
    password_confirm_error: "Passwort und Bestätigung stimmen nicht überein!",
    register_send_activation_email: "Bitte klicken Sie auf den Aktivierungscode den wir Ihnen per Mail gesendet haben!",
    recover_password: "Password wiederherstellen",
    new_password: "neues Passwort",
    confirm_new_password: "Bestätigung neues Passwort",
    password_hint: "Mindestens 8 Zeichen",
    recover_send_reset_email: "Bitte klicken Sie auf den Wiederherstellungslink den wir an Ihre Email gesendet haben!",
    password_changed: "Neues Passwort erfolgreich geändert!",

    checklist_title: "Ihre persönliche Checkliste",
    checklist_subtitle: "Wenn ein Familienmitglied stirbt, müssen trotz der Trauer viele bürokratische Angelegenheiten geklärt werden. In dieser emotionalen Ausnahmesituation droht die Bürokratie uns zu überfordern.",
    checklist_checkliste: "Checkliste",
    checklist_meldepflichtig: "Meldepflichtig",
    checklist_frist_nach_todesfall: "Frist nach Todesfall",
    checklist_besonderheiten: "BESONDERHEITEN",
    checklist_notizen: "Notizen",
    checklist_versicherungen: "Versicherungen",
    checklist_mitgliedschaften: "Mitgliedschaften",

    collections_botschaften_title: "Persönliche Botschaften",
    collections_botschaften_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",
    collections_wunsch_title: "Der letzte Wunsch",
    collections_wunsch_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",
    collections_select_panel: "Plan auswählen:",
    
    empty_trusters: "Sie haben keine Vertrauensperson gewählt!",
    cancel: "Abbrechen",
    save: "speichern",
    photos: "Fotos",
    documents: "Briefe",
    videos: "Videos",
    audios: "Sprachnachrichten",
    create_new_category: "Erste Kategorie erstellen",
    play: "Play",
    description: "Beschreibung",
    size: "Größe",
    date: "Datum",
    actions: "Aktionen",

    upload_new_file: "Laden Sie hier weitere Dokumente hoch",
    no_file: "Keine Datei vorhanden!",
    are_you_sure: "Sind Sie sicher?",
    delete_file_alert: "Das Löschen dieser Datei ist nicht mehr möglich. Diese Datei wird auch aus der Liste der vertrauenswürdigen Benutzerdateien entfernt",
    delete_category_alert: "Durch das Löschen der Kategorie werden alle darin enthaltenen Dateien gelöscht und können nicht mehr wiederhergestellt werden",
    trusted_dialog: "Vertrauenspersonen",
    accessed_users_to_file: "Liste Ihrer vertrauenswürdigen Benutzer, die auf diese Datei zugreifen. Sie können diese Benutzer hinzufügen oder entfernen!",
    add_new: "Neue hinzufügen",
    trusted_user: "Vertrauensperson",
    trusted_group: "Vertrauens Gruppe",
    category_name: "Kategorie Name",
    yes: "Ja",
    no: "Nein",
    later: "Später",
    buy_now: "Zu den Mitgliedschaften",
    buy: "Kaufen",
    need_payment: "Keine Mitgliedschaft vorhanden",
    need_payment_desc: "Um auf diesen Bereich zugreifen zu können, müssen Sie eine Mitgliedschaft abschließen.",
    
    upload_new_media_subtitle: "Dateiformate: JPG, JPEG, PNG, BMP, GIF, SVG, AVI, MP4, MKV, FLV, WEBM, MOV, WMV, MP3, WAV, M4A, OGG",
    upload_new_document_subtitle: "Dateiformate: TXT, DOC, DOCX, PDF, ZIP, XLSX, PPT, PPTX",
    uploading: "Uploading ... bitte warten",
    upload: "Upload",
    dont_close_page: "Bitte die Seite nicht schliessen",
    file: "Datei",
    new_file: "neue Datei",
    select_file: "Bitte Dateien wählen",
    file_desc: "Datei Bezeichnung",
    media_type_alert: "Die Datei muss der Typ der entsprechenden Mediadateien sein",
    file_stored: "Neue Datei gespeichert!",
    
    logout: "Abmelden",

    dashboard: "Dashboard",
    botschaften: "Persönliche Botschaften",
    checkliste: "Checkliste",
    tresor: "Tresor",
    trusted_menu: "Vertrauenspersonen",
    up: "Up",
    up_subtitle: "Dieser Bereich ist in Kürze für Sie verfügbar. Wir werden Sie über Neuigkeiten informieren.",
    support: "Support",
    support_tickets: "Support Tickets",
    profile: "Profil",
    wunsch: "Der letzte Wunsch",
    pending: 'Ausstehend',
    open: "Offen",
    closed: "Geschlossen",
    close: "Schließen",
    comments: "Bemerkungen",
    accepted: "Akzeptiert",
    rejected: "abgelehnt",
    pays: "Zahlungen",
    all: "alle",
    successful: "erfolgreich",
    amount: "Betrag",
    death_certificates: "Sterbeurkunden",

    information: "Persönliche Daten",
    personal_information: "Persönliche Informationen",
    keys: "Schlüssel",
    account: "Zahlung",
    trusters: "Vertrauter",
    truster: "Vertrauter",
    notifications: "Benachrichtigungen",
    tap_to_edit: "Klicken um zu ändern",
    status: 'Status',
    plan_subscription: 'Mitgliedschaft',
    subscription_users: 'Abonnement-Nutzer',

    age: "Alter",
    martial_status: "Familienstand",
    verheiratet: "Verheiratet",
    single: "Ledig",
    indent_nr: "Rentenversicherungsnummer",
    address: "Adresse",
    street: "Strasse",
    house_number: "Hausnummer",
    zip: "PLZ",
    city: "Stadt",
    height: "Größe",
    spouse: "Partner",
    job: "Beruf",
    birthplace: "Geburtsort",
    gender: "Geschlecht",
    children: "Kinder",
    birthdate: "Geburtstag",
    nationality: "Nationalität",
    national_code: "Ausweis-Nr.",
    phone: "Telefon",
    
    keys_title: "Sicherheitsschlüssel für Verschlüsselung und Entschlüsselung",
    keys_subtitle1: "Mit den folgenden Schlüsselpaaren können Sie Ihre Dateien sicher ver- und entschlüsseln",
    keys_subtitle2: "Verschlüsselte Dateien können nur mit diesen Schlüsseln wiederhergestellt werden.",
    keys_subtitle3: "Wenn die Schlüssel verloren gehen, gehen alle damit verschlüsselten Dateien verloren.",
    keys_subtitle4: "Der private Schlüssel wird nur einmal erstellt, also seien Sie sorgfältig bei der Aufbewahrung.",
    public_key: "Öffentlicher Schlüssel",
    private_key: "Privater Schlüssel",
    generate_key_pairs: "Neue Schlüsselpaare generieren",
    revoke_keys: "Schlüssel aufheben",
    download_keys: "Herunterladen",
    please_wait: "Bitte warten ...",
    choose_prv_key: "Bitte wählen Sie Ihren privaten Schlüssel (.pem file) für Entschlüsselungsprozesse.",
    prv_pem_file: "Privater Schlüssel Pem Datei",

    prv_dont_access: "Aus Datenschutz- und Sicherheitsgründen haben wir keinen Zugriff auf Ihren Schlüssel. Achten Sie daher darauf, Ihren privaten Schlüssel aufzubewahren. Wir können Ihre Daten nicht wiederherstellen, wenn Sie Ihren Schlüssel verlieren  ",  
    downloading: "Downloading, bitte warten!",
    key_updated: "Schlüssel aktualisiert!",
    notifications_subtitle: "In diesem Abschnitt werden alle Ihre Nachrichten und Aktivitäten angezeigt.",
    title: "Titel",
    message: "Nachricht",
    empty_notifications: "Derzeit ist keine Nachricht für Sie vorhanden",
    account_info: "Kontoübersicht",
    current_password: "aktives Passwort",

    pay_verify: "Zahlung überprüfen",
    pay_success: "Ihre Zahlung war erfolgreich. Ihr Abonnement ist aktualisiert worden",
    pay_error: "Ihre Zahlung ist fehlgeschlagen. Sie können zum Abschnitt Abonnements zurückkehren und Ihren Vorgang wiederholen.",
    plans: "Zahlungspläne",
    plans_subtitle: "Buchen Sie hier Ihre gewünschte Mitgliedschaft",
    remaining_days: "Tage übrig",
    days: "Tage",
    storage: "Speicherplatz",
    task_limit: "Aufgaben Limit",
    trusted_limit: "Limit an Vertrauenspersonen",
    duration: "Dauer",
    price: "Preis",
    plan_empty: "Es gibt keine Plan!",
    redirect_payment_gateway: "Weiterleitung zur Zahlungs-Seite ...",
    redirect_shop: "Du wirst nun zum Shop weitergeleitet ...",
    truster_users: "Vertrauter Benutzer",
    truster_users_subtitle: "In diesem Abschnitt können Sie Ihre Vertrauenspersonen sehen und verwalten.",
    truster_users_subtitle2: "Sterbeurkunde: Mit dieser Funktion können Sie die Sterbeurkunde der gewünschten Vertrauensperson zur Überprüfung an Geras senden.",
    uploaded_certificates: "Hochgeladene Zertifikate",
    confirm_trusted: "Akzeptieren Sie die Vertrauensanfrage?",
    truster_empty: "Es gibt keine Vertrauensperson!",
    redeem_code_success: "Ihr Abo wurde erfolgreich aktiviert",
    redeem_code_error: "Ihr Abo konnte nicht aktiviert werden",

    upload_certificates_confirm_dialog: "Beim Hochladen der Sterbeurkunde wird das Konto des Benutzers geschlossen, wenn die Sterbeurkunde bestätigt wird",
    upload_certificate_dialog_title: "Sterbeurkunde hochladen",
    upload_certificates_dialog: "Bitte senden Sie die Sterbeurkunde als Datei des Benutzers zur Überprüfung",
    certificate_file: "Sterbeurkunden-Datei",
    certificate_file_desc: "Please Enter Request Description",
    enter_certificate_file: "Please Upload Certificate File",
    enter_certificate_file_desc: "Please Write Certificate File Description",

    new_ticket: "Neues Ticket",
    new_ticket_subtitle: "Bitte schreiben Sie Ihre Nachricht und senden Sie diese an die zuständige Abteilung",
    loading: "Lädt… bitte warten",
    empty_data: "Keine Support-Tickets vorhanden",
    department: "Abteilung",
    subject: "Betreff",
    last_update: "letzte Änderung",
    error: "Fehler",
    send: "senden",
    ticket: "Ticket",
    created_at: "Erstellt am",
    new_message: "Neue Nachricht",
    tresor_subtitle: "Ein sicherer Ort für Ihre Dateien, fortschrittliche Verschlüsselung, die nur Sie und Ihre Treuhänder öffnen können",

    no_keys: "Sicherheitsschlüssel nicht gefunden!",
    no_keys_subtitle: "Um auf diesen Abschnitt zuzugreifen, müssen Sie zuerst die Sicherheitsschlüssel für Ihr Konto erstellen.",
    create_now: "Jetzt erstellen!",
    trusted_users2: "Vertraute Personen",
    trusted_users2_subtitle: "Liste Ihrer vertrauenswürdigen Benutzer, die auf diese Datei zugreifen. Sie können diese Benutzer hinzufügen oder entfernen!",
    file_upload_success: "Datei erfolgreich hochgeladen!",

    trusted_title: "Vertrauenspersonen",
    trusted_subtitle: "In diesem Abschnitt können Sie nach Benutzern suchen und sie zu Ihrer Vertrauensliste hinzufügen. Sie können sie auch in Gruppen kategorisieren",
    trusted_subtitle2: "Die Benutzer können auf Dateien, die Sie freigeben zugreifen.",
    trusted_subtitle3: "Treuhänder 1. Grades: Diese Treuhänder haben mehr Zugriff als andere. Sie können beispielsweise eine Sterbeurkunde hochladen.",

    users: "Benutzer",
    groups: "Gruppen",
    wait_to_confirm: "Warten auf Bestätigung…",
    no_trusted: "Es gibt keine Vertrauensperson!",
    new_group_name: "Neuer Gruppenname",
    add: "Hinzufügen",
    delete_trusted_alert: "Vertrauensperson wirklich entfernen?",
    delete_group_alert: "Durch das Entfernen der Gruppe werden alle Benutzer in der Gruppe und alle erteilten Berechtigungen von ihnen weggenommen.",
    resend_email_alert: "Soll die E-Mail an die Vertrauensperson erneut versendet werden?",
    truster_email_resent: "E-Mail an die Vertrauensperson wurde versendet",
    search_user: "Suche Benutzer",
    enter_username_name_to_search: "Bitte geben Sie den Namen oder Benutzernamen des Benutzers ein, den Sie zu Ihrer Liste hinzufügen möchten",
    name_username: "Name oder Benutzername",
    start_type_to_search: "Starte Eingabe zum suchen",
    group_optional: "Gruppe (optional)",
    add_to_trusted: "Hinzufügen zu meinen vertrauten Personen",
    edit_trusted: "Speichern",
    without_group: "ohne Gruppe",
    trusted_added_success: "Benutzer erfolgreich zu Ihrer Liste hinzugefügt!",
    trusted_removed_success: "Benutzer erfolgreich von Ihrer Liste entfernt!",
    group_removed: "Gruppe erfolgreich entfernt!",

    token_expired: "Ihre Push-Tan ist abgelaufen. Bitte melden Sie sich erneut an!",
    organisation: "Organisation",
    start_at: "startet um",
    expire_at: "abgelaufen am",
    start_at_hint: "Ohne Eingabe unbegrenzte Zeit!",
    expire_at_hint: "Ohne Eingabe Startdatum nach dem Tod!",

    prv_pem_invalid: "َDer Schlüssel ist ungültig. Bitte eine gültige .pem-Datei hochladen.",

    widowed: 'Verwitwet',
    divorced: 'Geschieden',
    registered_civil_partnership: 'In Eingetragener Lebenspartnerschaft',

    male: 'Männlich',
    female: 'Weiblich',
    other_gender: 'Diverse',
    invite_code: 'Code für Freunde einladen',
    copy_invite_link: "Einladungslink kopieren",
    copied: 'Kopiert!',

    features: 'Merkmale',

    add_note_to_checklist: 'Notiz hinzufügen',
    note: 'Notiz',

    send_invite_email: 'Einladungs-E-Mail senden',
    invite_email_lbl: 'Einladungs-E-Mail',
    invite_email_placeholder: 'Empfänger',
    invite_email_sended: 'Einladungs-E-Mail gesendet.',
    invite_email_empty: 'Bitte geben Sie eine E-mail-Adresse ein',

    personaldocs: 'Persönliche Unterlagen',
    documenttemps: 'Dokumentvorlagen',
    documenttemps_subtitle: 'Laden Sie hier verschiedene Dokumentvorlagen herunter.<br>Bitte beachten Sie dass Sie für die ausfüllbaren Formulare den Adobe Acrobat Reader benötigen.',

    personaldocs_title: 'Persönliche Unterlagen',
    personaldocs_subtitle: 'Laden Sie hier Ihre persönlichen Unterlagen aller Art hoch',

    advisor: 'Berater-Unterlagen',
    advisor_title: 'Berater-Unterlagen',
    advisor_subtitle: 'Laden Sie hier Unterlagen für Ihren Berater hoch',

    subscriptions: 'Preismodell-Abonnement',
    subscriptions_subtitle: 'Hier finden Sie alle Nutzer für Ihr gewähltes Preismodell',

    empty_duplications: 'Ohne Abonnementbenutzer',
    expired_at: 'Abgelaufen am',

    otp_title: 'Zwei-Faktor-Authentifizierung',
    otp_setup: 'Geben Sie den 6-stelligen Sicherheitscode aus Ihrer Authentifizierungs-App ein (z.B. Google Authenticator)',
    otp_code: 'Sicherheitscode',
    success_otp_enabled: 'Zweistufige Anmeldung mit pushTAN aktiviert',
    success_otp_disabled: 'Zweistufige Anmeldung mit pushTAN deaktiviert',

    init_security_keys: 'Erstkonfiguration von Verschlüsselungen',

    add_user: 'Vertrauenspersonen hinzufügen',
    edit_user: 'Vertrauensperson editieren',
    add_trusted_user_desc: 'In diesem Abschnitt können Sie Personen eintragen, denen Sie vertrauen.',

    your_pays: 'Ihre Zahlungen',
    your_pays_desc: 'Hier finden Sie den Zahlungsbericht',
    empty_your_pays: 'Sie haben derzeit keine registrierten Zahlungen',
    
    payment_title: 'Bestelldetails',
    payment_plan_price: 'Preis',
    payment_amount: 'Zahlungsbetrag',
    paymeny_go_bank: 'Zahlen Sie mit PayPal',
    payment_plan: 'Ausgewählter Plan',

    ref: 'Ref.',

    started: 'Warten auf Zahlung',

    send_contact_email_success: 'Vielen Dank für Deine Nachricht',

    not_activated: "Nicht aktiviert",
    activated: "Aktiv",
    your_abos: "Ihre Mitgliedschaft",
    your_abos_subtitle: "Buchen oder aktivieren Sie hier Ihre Mitgliedschaft.",
    your_abos_list: "Gebuchte Mitgliedschaft",
    no_abos: "Noch keine gebuchte Mitgliedschaft vorhanden.<br>Bitte wählen Sie eine Mitgliedschaft oder geben Sie einen bereits erhalten Code ein.",
    abonnement: 'Mitgliedschaft',

    last_wish_title: 'Der letzte Wunsch',
    last_wish_desc: 'Hinterlassen Sie hier Ihren letzten Wunsch',

    meta_tags: {
        home: {
            title: 'Geras 24',
            description: 'Geras24'
        },
        prices: {
            title: 'Preise - Geras 24',
            description: 'Preise'
        },
        products: {
            'der-letzte-wunsch': {
                title: 'Der letzte Wunsch - Geras 24',
                description: 'Der letzte Wunsch'
            },
            'persoenliche-botschaft' : {
                title: 'Persönliche Botschaft - Geras 24',
                description: 'Persönliche Botschaft'
            },
            checkliste: {
                title: 'Checkliste - Geras 24',
                description: 'Checkliste'
            },
            tresor: {
                title: 'Tresor - Geras 24',
                description: 'Tresor'
            },
            up: {
                title: 'Up - Geras 24',
                description: 'Up'
            },
        },
        impressum: {
            title: 'Impressum - Geras 24',
            description: 'Impressum'
        },
        datenschutz: {
            title: 'Datenschutz - Geras 24',
            description: 'Datenschutz'
        },
        sellerTerms: {
            title: 'Allgemeine Verkaufsbedingungen - Geras 24',
            description: 'Allgemeine Verkaufsbedingungen'
        },
        dataProtection: {
            title: 'Datensicherheit - Geras 24',
            description: 'Datensicherheit'
        },
        videos: {
            title: 'Filme - Geras 24',
            description: 'Filme'
        },
        about: {
            title: 'Über uns - Geras 24',
            description: 'Über uns'
        },
        partner: {
            title: 'Partner - Geras 24',
            description: 'Partner'
        },
        contactUs: {
            title: 'Kontakt - Geras 24',
            description: 'Kontakt'
        },
        faq: {
            title: 'Hilfe - Geras 24',
            description: 'Hilfe'
        },
        notfallBox: {
            title: 'Die Geras24 Notfallbox - Geras 24',
            description: 'Geras24 Notfallbox'
        },
        news: {
            title: 'Neues und Presse - Geras 24',
            description: 'Neues und Presse'
        },
        promoCodes: {
            title: 'Geras24 Promo Codes - Geras 24',
            description: 'Geras24 Promo Codes'
        },
        insuranceComparison: {
            title: 'Versicherungsvergleich - Geras 24',
            description: 'Geras24 Versicherungsvergleich - Risikolebensversicherung – Sterbegeldversicherung – Hausratversicherung - Privathaftpflichtversicherung'
        },
    },

    texts: {
        home: {
            introTitle: '',
            introDescription: 'Geras24 ist die zukunftsweisende digitale Plattform, die dir und deiner Familie eine umfassende Absicherung bietet. Unser Ziel ist es, dich und deine Lieben ein Leben lang zu begleiten und auch nach deinem Ableben für sie da zu sein. Wir setzen alles daran, deine Familie und sogar deine engsten Freunde in unsere Betreuung mit einzubeziehen. Lass dich überraschen von den vielfältigen Möglichkeiten, die Geras24 für dich bereithält!',
            section1Title: '',
            section1Description: 'Geras24 ist dein unverzichtbarer digitaler Partner im Alltag, der dir dabei hilft, dein Leben perfekt zu organisieren. Mit Geras24 hast du die Möglichkeit, all deine wichtigen Dokumente sicher im digitalen Tresor zu verwahren. Ob es um deine jährliche Steuererklärung geht oder um die Absicherung deiner Familie im Notfall - Geras24 sorgt dafür, dass alles perfekt geregelt ist. Mit Geras24 bist du immer einen Schritt voraus und kannst dich entspannt zurücklehnen. Also denke voraus und lass Geras24 dir dabei helfen!',
            section2Title: '',
            section2Description: 'Geras24 hat es sich zum Ziel gesetzt, dich und deine Familie optimal bei der Absicherung zu unterstützen. Eine wichtige Komponente dabei sind Versicherungen. Hier findest du eine Übersicht über die wichtigsten Versicherungen, die du abschließen solltest. Unser unabhängiger Partner FIDELIS FINANZEN gibt dir wertvolle Empfehlungen. Nutze unseren Versicherungsvergleich, um die passende Absicherung für dich zu finden. Vergleiche hier folgende Versicherungen:',
            section3Title: '',
            section3Description: 'Unser Bestreben ist es, sowohl dich wie auch deine Familie im Notfall abzusichern und alles so zu organisieren, dass alles nach deinen Wünschen geregelt ist. Wir möchten sicherstellen, dass dein Vermächtnis respektiert wird und deine Vorstellungen umgesetzt werden, ohne dass andere Einfluss darauf nehmen. Nutze die Chance und sichere dein Leben jetzt ab, um auch digital weiterzuleben. Erfahre mehr über unsere umfassenden Leistungen.',
            section4Title: '',
            section4Description: 'Geras, der göttliche Herrscher über das hohe Alter im griechischen Pantheon, symbolisiert unsere Sehnsucht nach einem langen und erfüllten Leben. Doch letztendlich werden wir alle mit dem unausweichlichen Ende konfrontiert. Unsere Liebsten verlassen uns und auch wir selbst werden eines Tages gehen müssen. Der Zeitpunkt bleibt ungewiss, jedoch sollten wir uns bereits zu Lebzeiten auf diesen Moment vorbereiten, um sicherzustellen, dass unsere Wünsche und Vorstellungen respektiert werden. Wir setzen uns dafür ein, dass du auch nach deinem Ableben noch allgegenwärtig bist.',
        },
        products: {
            section1Title: '',
            section1Description: 'Bist du gerade Elternteil geworden und denkst jetzt über dein Leben nach? Dann befindest du dich schon auf dem richtigen Weg. Denn oftmals ist es wichtiger, zuerst an morgen zu denken, damit wir ein friedlicheres Heute erleben können. Denn für uns alle kommt irgendwann der Moment, an dem wir die Welt verlassen müssen. Danach sind unsere Liebsten auf sich alleine gestellt. Damit ihnen das so leicht wie möglich fällt, solltest du heute schon darüber nachdenken, was notwendig ist, um für die Zeit nach deinem Tod alles geregelt zu haben. Lass Dich inspirieren und werde jetzt Teil unserer Familie.',
            section2Title: '',
            section2Description: 'Geras24 steht für „Heute schon an morgen denken und den Tag danach“. Denn warum solltest du alle anfallenden bürokratischen Aufgaben denen überlassen, die nach deinem Ableben um dich trauern? Und warum soll nach deinem Tod nicht alles so ablaufen, wie du es dir selbst wünschst? Damit du genau den Abschied bekommst, den du dir vorstellst und damit deine Liebsten nicht im bürokratischen Chaos versinken, solltest du heute schon an morgen denken…und eben den Tag danach. Geras24 hilft dir dabei. Halte jetzt schon fest, wie genau du dir deine eigene Beerdigung vorstellst, hinterlasse persönliche Botschaften an deine Liebsten, gründe eine digitale Gemeinschaft, zu der du all jene Menschen einlädst, die dir am Herzen liegen. Es wird der Moment kommen, in dem es zu spät ist, um noch vorzusorgen. Deswegen ist das „Jetzt“ wichtig, um die ersten Schritte in die richtige Richtung zu gehen. Hast du für dich und deine Familie vorgesorgt, musst du nicht mehr an morgen denken, sondern kannst das Heute in vollen Zügen und mit einem ruhigen Gewissen genießen. Sorge noch heute dafür, deine Familie abzusichern und lebe dein Leben so, wie du es dir vorstellst. Frei von Sorgen um das was passiert, wenn du nicht mehr für deine Lieben da sein kannst.' +
                '<br /><br />Mit Geras24 steht dir ein Portal zur Seite, welches sich an Vorsorgende jeden Alters wendet. Wir begleiten dich durch dein gesamtes Leben und helfen dir, die richtigen Entscheidungen zu treffen. Bleibe sicher – mit Geras24.' +
                '<br /><br />Bei der Neugewinnung von Kunden nehmen Versicherungsagenturen in der Regel den Weg über das Internet. Geras24 will zeigen, wie der Vertrieb von potenziellen Versicherungen in Zukunft aussehen kann. Wir begleiten dich mit deiner jungen Familie durch das gesamte Leben und empfehlen dir Versicherungen, die für euch alle wichtig sein könnten. Deswegen geht Geras24 wertvolle Partnerschaften mit Versicherungen ein, um dir die beste Auswahl bieten zu können. Doch das ist nur ein günstiger Aspekt in der Betreuung durch Geras24. Bei uns geht es um viel mehr.' +
                '<br /><br />Mit dem eigenen Ableben ist die Betreuung durch Geras24 noch lange nicht an ihrem Ende angekommen. Zu Lebzeiten triffst du die optimale Vorsorge für Deine Familie. Nach deinem Ableben erhalten Freunde und Bekannte durch „Geras24-Up“ einen eigenen Zugang in unserer Gemeinschaft. Hier können sie sich über die schönsten Momente mit dir austauschen, in einem digitalen Kondolenzbuch ihre Gefühle ausdrücken und sich untereinander Trost spenden. So lebst du digital für deine Liebsten weiter. Unsere digitale Gedächtnistafel und die digitale Todesanzeige sind wichtige Bestandteile in der Trauerarbeit mit deinen Angehörigen. So stellen wir deiner Familie nach deinem Tod eine wichtige Plattform zur Kommunikation zur Verfügung, damit du weiterhin bei ihnen sein kannst.',
            checklist: {
                introTitle: 'Die Checkliste nach einem Todesfall',
                introDescription: 'Stell dir vor, ein geliebter Mensch ist plötzlich verstorben und du stehst als Angehöriger vor der Aufgabe, alle Angelegenheiten zu regeln. Doch keine Sorge, wir haben eine kostenlose Checkliste für dich erstellt, die dir Schritt für Schritt zeigt, was nach einem Todesfall zu erledigen ist. Von der Kündigung von Verträgen bis hin zur Auszahlung von Lebensversicherungen - wir haben alles im Blick. Als Mitglied bei Geras24 kannst du dich darauf verlassen, dass wir dir diese mühsame Arbeit abnehmen. Denn mit uns kannst du bereits zu Lebzeiten alles Wichtige regeln. Lass uns dir helfen und werde noch heute Mitglied!',
                description: 'Im Laufe unseres Lebens schließen wir viele Versicherungen ab, darunter auch solche, die den Tod betreffen. Mit unserer Checkliste kannst du überprüfen, ob du ausreichend vorgesorgt hast und eine optimale Absicherung für deine Liebsten gewährleistet ist. Zudem findest du hier eine Liste, die im Todesfall eines Familienmitglieds nützlich sein kann. Der Verlust eines nahestehenden Menschen bringt nicht nur Trauer, sondern auch bürokratische Angelegenheiten mit sich. In dieser emotionalen Ausnahmesituation kann die Bürokratie schnell überfordern. Unsere Liste bietet eine geeignete Hilfestellung bei der Bewältigung der Formalitäten. Sie enthält alle relevanten Versicherungen, Verträge, Abonnements und Mitgliedschaften mit Fristen und besonderen Bedingungen übersichtlich aufgelistet. So gelingt eine schnelle und strukturierte Abwicklung der notwendigen bürokratischen Angelegenheiten.',
            },
            lastWish: {
                introTitle: 'Der letzte Wunsch',
                introDescription: 'Jeder hat für die Zeit nach seinem Tod eine andere Vorstellung davon, was er als richtig und schön empfindet. Viele können sich eine klassische Beerdigung nicht vorstellen. Sie wollen keine trauernden Gäste, sondern Freunde und liebe Menschen um sich wissen, die an die schönen Zeiten denken. Nur du bist in der Lage, schon zu Lebzeiten zu bestimmen, was dir wichtig ist. Halte deinen letzten Wunsch so detailliert wie möglich fest und hinterlege ihn auf der Plattform von Geras24. So erleichterst du deinen Liebsten die ohnehin schwere Zeit nach deinem Tod. Werde noch heute ein Mitglied der Geras24 Gemeinschaft und halte fest, wie dein letzter Wunsch genau aussieht.',
                description: 'Natürlich gibt es niemanden, der sich gerne mit dem eigenen Ableben beschäftigen möchte. Über viele Generationen war das Thema Tod ein Tabuthema. Doch eines ist absolut gewiss: der Tag des Abschieds wird für jeden von uns kommen. Dann sind es deinen Liebsten, die sich um die Planung deiner Beerdigung kümmern müssen. Und das in einer Zeit, die für sie ohnehin nicht einfach zu bewältigen ist. Sorge am besten noch zu Lebzeiten für deine ganz eigene Planung. Wie möchtest du bestattet werden und wo? Sollen die Gäste einen Dresscode einhalten? Sollen sie aus der Trauerfeier vielleicht sogar lieber eine Lebensfeier machen? Welche Musik soll gespielt werden und welches Essen soll es geben?' +
                    '<br /><br />Halte all das selbst fest und sorge so dafür, dass deine Liebsten nach deinem Tod ganz genau wissen, was zu tun ist. Das stellt sie nicht vor große Herausforderungen, sondern gibt ihnen einen Leitfaden an die Hand. Hinterlege deinen letzten Wunsch auf der Plattform von Geras24 und bearbeite ihn jederzeit neu, wenn du das möchtest. So überlässt du nicht deiner Familie die gesamte Planung, sondern übernimmst selbst Verantwortung für das, was nach deinem Tod kommen soll. Deine Liebsten können sich so in Ruhe von dir verabschieden, ohne sich Sorgen darum machen zu müssen, was du gerne gehabt hättest.',
            },
            personalMessage: {
                introTitle: 'Persönliche Botschaft',
                introDescription: 'Eine ganz besondere Funktion bei Geras24 ist die persönliche Botschaft. Hier hast du die Möglichkeit, deinen Liebsten Videos, Sprachnachrichten oder Briefe zukommen zu lassen. So bleibst du ihnen auch über den Tod hinaus erhalten und schaffst wertvolle Momente.',
                description: 'Es gibt so einiges, was wir unseren Liebsten auch zu Lebzeiten nie sagen. Oder es gibt Worte, die wir viel zu selten verwenden. Oftmals wünschen wir uns, dass wir darauf mehr Acht gegeben hätten. Wem möchtest du einen gut gemeinten Rat geben oder eine Weisheit übermitteln? Wen möchtest du um Verzeihung bitten? Wem möchtest du deine Gefühle offenbaren? Wer soll dein Lieblingsrezept mit der geheimen Zutat erfahren? Der Bereich „Persönliche Botschaft“ wurde von Geras24 eigens für solche Anliegen eingerichtet. Hier hast du Platz für alle Botschaften, die du noch übermitteln willst. Egal ob Fotos, Briefe, Videos oder sonstige Erinnerungen. Diese kannst du alle hochladen und speichern und so deinen Liebsten hinterlassen. Sorge schon jetzt dafür, dass deine Familie, Freunde und Verwandte nach deinem Tod schöne Momente miteinander teilen können. Sorge für AHA-Momente, Tränen der Freude und lustige Erinnerungen.',
            },
            digitalSafe: {
                introTitle: 'Der digitale Tresor',
                introDescription: 'Eines der Hauptprodukte von Geras24 ist die Möglichkeit, alle wichtigen Dokumente sicher hochzuladen. Hier findest du alles, was du für deine tägliche Buchhaltung benötigst, wie zum Beispiel Kontoauszüge, Versicherungspolicen oder Arztrechnungen. Doch nicht nur für den aktuellen Bedarf ist diese Funktion nützlich - du kannst auch alle Dokumente sicher abspeichern, um für den Fall eines schweren Unfalls oder im Todesfall sicherzustellen, dass deine Hinterbliebenen auf alle wichtigen Unterlagen zugreifen können. Registriere dich jetzt kostenlos und nutze diese wertvolle Funktion!',
                description: 'Es ist nicht ungewöhnlich, wenn du bei all den verschiedenen Verpflichtungen wie Versicherungen, Abonnements, Verträgen, Passwörtern, Social Media Accounts und Konten schnell den Überblick verlierst. Oft musst du dich durch viele Unterlagen und Ordner wühlen, um das Gesuchte zu finden. Mit dem Tresor von Geras24 kannst du diesen zeitaufwendigen und stressigen Prozess vermeiden, indem du alle wichtigen Dokumente, Unterlagen und Notizen digitalisierst und sicher hinterlegst.' +
                    '<br /><br />Der Tresor bietet einen sicheren Platz für Finanz- und Bankangelegenheiten, Rechnungen, Bescheinigungen, Dokumente zum Besitz oder Versicherungspapiere. Du hast jederzeit und von überall Zugriff auf deine Angelegenheiten und kannst sie verwalten. Durch das Ordnersystem kannst du deine Unterlagen und Bereiche kategorisch aufteilen, was die Suche nach dem passenden Dokument erheblich erleichtert und beschleunigt.' +
                    '<br /><br />Geras24 bietet dir nicht nur die Chance, alle lebensrelevanten Belange geschützt an einem zentralen Ort zu verwahren, sondern ermöglicht auch den von dir auserwählten Vertrauten im Notfall einen raschen Zugang zu allen erforderlichen Informationen. Im Safe kannst du zum Beispiel eine Abschrift deines letzten Willens, eine Patientenverfügung oder eine Vorsorgevollmacht hinterlegen, die deinen Angehörigen bei Bedarf bereitgestellt werden.',
            },
        },
    }
}
