import axios from 'axios'
import Vue from 'vue'

export default {
    async adminGetPlans({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/plans`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSetPlan({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/plans`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetFeatures({ commit }) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/features`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeletePlan({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/plans/${id}`, method: 'DELETE' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetPlan({ commit }, id) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/plans/${id}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdatePlan({ commit }, data) {

        return new Promise((resolve, reject) => {
            axios({ url: `/admin/plans/${data.id}`, data: data, method: 'PUT' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}